'use strict';

require('../handlers/message/layer-message-viewer');

require('./layer-message-viewer-expanded');

require('./status/layer-status-message-view');

require('./response/layer-response-message-view');

require('./text/layer-text-message-view');

require('./link/layer-link-message-view');

require('./image/layer-image-message-view');

require('./buttons/layer-buttons-message-view');

require('./choice/layer-choice-message-view');

require('./layer-standard-message-view-container');

require('./layer-titled-message-view-container');

require('./layer-dialog-message-view-container');
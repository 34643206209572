// @flow
import React from 'react';
import {
  Body1,
  Body2,
  Headline1,
  Headline2,
  Headline3,
  Headline4,
  Headline5,
  Headline6,
} from '@material/react-typography';
import './style.scss';

type Props = {
  mt?: number,
  mb?: number,
  ml?: number,
  mr?: number,
  mx?: number,
  my?: number,
  display?: string,
  flexWrap?: string,
  textAlign?: string,
  fontSize?: string,
  wordBreak?: string,
};

const mapComponentByFontSize = {
  fontSize: Body2,
  htmlFontSize: Body1,
  'h1.fontSize': Headline1,
  'h2.fontSize': Headline2,
  'h3.fontSize': Headline3,
  'h4.fontSize': Headline4,
  'h5.fontSize': Headline5,
  'h6.fontSize': Headline6,
};

const gutterWidth = 10;

function getStyleFromProps({
  mt,
  mb,
  ml,
  mr,
  display,
  flexWrap,
  textAlign,
  wordBreak,
}) {
  return {
    marginTop: gutterWidth * mt,
    marginBottom: gutterWidth * mb,
    marginLeft: gutterWidth * ml,
    marginRight: gutterWidth * mr,
    display,
    flexWrap,
    textAlign,
    wordBreak,
  };
}

// a compatibility layer with the previous implementation
// why? `@material` doesn't provide any spacing/alignment helper
// font-size-wise, it's convenient to import only one component, this is the way `@material-ui` handled it
export default function Box({
  mt = 0,
  mb = 0,
  ml = 0,
  mr = 0,
  mx = 0,
  my = 0,
  display,
  flexWrap,
  textAlign,
  fontSize,
  wordBreak,
  ...props
}: Props) {
  const Component = (fontSize && mapComponentByFontSize[fontSize]) || 'div';
  const style = getStyleFromProps({
    mt: mt || my,
    mb: mb || my,
    ml: ml || mx,
    mr: mr || mx,
    display,
    flexWrap,
    textAlign,
    wordBreak,
  });

  return <Component style={style} {...props} />;
}

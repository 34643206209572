/**
 * The MessageTypeResponseSummary represents a the Message Part that contains all user Responses to a given Message Part.
 *
 * Message Part has a MIME Type of application/vnd.layer.responsesummary+json
 *
 * @class  Layer.Core.MessageTypeResponseSummary
 * @extends Layer.Core.Root
 */
'use strict';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _namespace = require('../../namespace');

var _namespace2 = _interopRequireDefault(_namespace);

var _syncable = require('../syncable');

var _syncable2 = _interopRequireDefault(_syncable);

var _root = require('../../root');

var _root2 = _interopRequireDefault(_root);

var _settings = require('../../../settings');

var _multiIdentityStateTracker = require('../../crdt/multi-identity-state-tracker');

var _multiIdentityStateTracker2 = _interopRequireDefault(_multiIdentityStateTracker);

var _utils = require('../../../utils');

var _layerError = require('../../layer-error');

var _index = require('./index');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var MessageTypeResponseSummary = function (_Root) {
  _inherits(MessageTypeResponseSummary, _Root);

  function MessageTypeResponseSummary(options) {
    _classCallCheck(this, MessageTypeResponseSummary);

    var _this = _possibleConstructorReturn(this, (MessageTypeResponseSummary.__proto__ || Object.getPrototypeOf(MessageTypeResponseSummary)).call(this, options));

    _this._trackers = {};
    return _this;
  }

  _createClass(MessageTypeResponseSummary, [{
    key: 'reset',
    value: function reset() {
      var _this2 = this;

      Object.keys(this._trackers).forEach(function (stateName) {
        return _this2._trackers[stateName].reset();
      });
      this.part = null;
    }
    /**
     * Register a state name that is used by this Message Type, and create a Tracker for that state.
     *
     * There are 3 categories of state:
     *
     * 1. State that is modified by this client, and which other clients listen to
     * 2. States that are modified by other clients and which this client listens to
     * 3. States that are modified by this and other clients and which this client listens to
     *
     * Any other category would mean that this client does not care about the value and does not
     * need to register a tracker for that state.
     *
     * Note that each Tracker tracks a given state name for all Identities stored within the message.
     *
     * * The Tracker instantiates one subtracker per user who has responded.
     * * The Tracker must instantiate new subtrackers on the fly as new
     *   responders post responses.
     *
     * @method registerState
     * @param {String} name
     * @param {String} type   Value chosen from Layer.Constants.CRDT_TYPES
     */

  }, {
    key: 'registerState',
    value: function registerState(name, type) {
      if (this._trackers[name]) {
        if (this._trackers[name].type !== type) throw new Error('Tracker cannot be reregistered');
        return;
      } // If we have existing Response Data (this.part), provide it to the new tracker to initialize its state.


      this._trackers[name] = new _multiIdentityStateTracker2.default({
        name: name,
        type: type
      });

      if (this.part) {
        this._trackers[name].synchronize(JSON.parse(this.part.body));
      }
    }
    /**
     * Adds the specified value to the specified state, and schedules a Response Message to be sent.
     *
     * Note that it is the Tracker's responsibility to generate and track Operation IDs.
     *
     * ```
     * model.responses.addState('click-count', 5);
     * ```
     *
     * @method addState
     * @param {String} name
     * @param {String} value
     */

  }, {
    key: 'addState',
    value: function addState(name, value) {
      var operations = this._trackers[name].addValue(value);

      if (operations && operations.length) {
        this._addOperations(operations);

        this.parentModel._triggerAsync('message-type-model:change', {
          property: 'responses.' + name,
          newValue: this.getState(name, _settings.client.user),
          addedValue: operations[0].value,
          oldValue: operations[0].oldValue,
          identityId: _settings.client.user.id
        });
      }
    }
    /**
     * Sets the named state to the specified value for the specified Identity ID.
     *
     * Use this to setup the initial state of the message when it reaches all participants.
     *
     * @method addInitialResponseState
     * @param {Object} options
     * @param {String} options.name  Name of the state to set (must be registered already)
     * @param {Mixed} options.value  Value of the state (String, Number, or Boolean)
     * @param {String} options.identityId  Identifies the user for whom this state will be registered as their initial state
     */

  }, {
    key: 'addInitialResponseState',
    value: function addInitialResponseState(_ref) {
      var name = _ref.name,
          value = _ref.value,
          identityId = _ref.identityId;

      if (!this.parentModel.initialResponseState) {
        this.parentModel.initialResponseState = [];
      }

      this.parentModel.initialResponseState.push({
        operation: 'add',
        type: this._trackers[name].type,
        value: value,
        name: name,
        id: (0, _utils.randomString)(6),
        identity_id: identityId
      });
    }
    /**
     * Removes the specified value (if present) from the specified state property, and schedules a Response Message to be sent.
     *
     * Note that it is the Tracker's responsibility to identify a suitable Operation ID
     * to remove, or else abort the operation.
     *
     * TODO: Remove any operations that havne't yet been sent from the Response Model.
     *
     * ```
     * model.responses.removeState('selected-color', 'red');
     * ```
     *
     * @method removeState
     * @param {String} name
     * @param {String} value
     */

  }, {
    key: 'removeState',
    value: function removeState(name, value) {
      var oldValue = this.getState(name, _settings.client.user);

      var operations = this._trackers[name].removeValue(value);

      if (operations && operations.length) {
        this._addOperations(operations);

        this.parentModel._triggerAsync('message-type-model:change', {
          property: 'responses.' + name,
          newValue: operations[0].value,
          oldValue: oldValue,
          identityId: _settings.client.user.id
        });
      }
    }
    /**
     * Sets the Status Text to be rendered to users when the next Response Message is sent.
     *
     * Can be called before or after {@link #addState} or {@link #removeState}
     * ```
     * var responseSummary = model.responses;
     * responseSummary.setResponseMessageText('User tried to delete the message but we wouldn't let them');
     * responseSummary.addState('tried-to-delete', true);
     * ```
     *
     * @method setResponseMessageText
     * @param {String} text
     */

  }, {
    key: 'setResponseMessageText',
    value: function setResponseMessageText(text) {
      this._createResponseModel();

      this._currentResponseModel.displayModel.text = text;
    }
    /**
     * Creates a Response Model to queue up operations if one hasn't already been created.
     *
     * @private
     * @method _createResponseModel
     */

  }, {
    key: '_createResponseModel',
    value: function _createResponseModel() {
      if (!this._currentResponseModel) {
        var ResponseModel = _namespace2.default.Client.getMessageTypeModelClass('ResponseModel');

        var StatusModel = _namespace2.default.Client.getMessageTypeModelClass('StatusModel');

        this._currentResponseModel = new ResponseModel({
          displayModel: new StatusModel({})
        });
      }
    }
    /**
     * Take the generated operations, put them in the Response Model and schedule the Response Model to be sent.
     *
     * @private
     * @method _addOperations
     * @param {Layer.Core.CRDT.Changes[]} operations
     */

  }, {
    key: '_addOperations',
    value: function _addOperations(operations) {
      if (operations && operations.length) {
        this._createResponseModel(); // The ResponseModel understands how to take a `set` operation and serialize it


        this._currentResponseModel.addOperations(operations);

        this._scheduleSendResponseMessage();
      }
    }
    /**
     * Schedule a Response Message to be sent with all accumulated operations within 100ms.
     *
     * Note that it could as easily be 1ms so that all current executing code can complete,
     * generating all required operations, and then promptly send it.
     *
     * @method _scheduleSendResponseMessage
     */

  }, {
    key: '_scheduleSendResponseMessage',
    value: function _scheduleSendResponseMessage() {
      var _this3 = this;

      if (!this._sendResponseTimeout) {
        this._sendResponseTimeout = setTimeout(function () {
          return _this3.sendResponseMessage();
        }, 100);
      }
    }
    /**
     * Generate a Message from the Response Model, and send it in our current Conversation.
     *
     * Note that this is automatically called after a short delay, any time
     * `addState` is called changing the state and requiring that state to be shared with all participants.
     * Calling it directly however insures other changes aren't accidently added to this Response Message.
     *
     * @method sendResponseMessage
     */

  }, {
    key: 'sendResponseMessage',
    value: function sendResponseMessage() {
      if (this.isDestroyed || !this._currentResponseModel || !this._currentResponseModel.operations.length) return;
      this._sendResponseTimeout = 0;

      if (this.parentModel.message && this.parentModel.part && !this.parentModel.message.isNew()) {
        this._currentResponseModel.responseTo = this.parentModel.message.id;
        this._currentResponseModel.responseToNodeId = this.parentModel.part ? this.parentModel.nodeId : this.parentModel.parentId;
        var evt = this.parentModel.trigger('message-type-model:sending-response-message', {
          respondingToModel: this.parentModel,
          responseModel: this._currentResponseModel,
          cancelable: true
        });

        if (evt.canceled) {
          this._currentResponseModel = null;
        } else {
          this._currentResponseModel.send({
            conversation: this.parentModel.message.getConversation()
          });

          this._currentResponseModel = null;
        }
      } else if (this.parentModel.message) {
        this.parentModel.message.once('messages:sent', this.sendResponseMessage.bind(this), this);
      } else {
        this.parentModel.once('message-type-model:has-new-message', this.sendResponseMessage.bind(this), this);
      }
    }
    /**
     * Returns an Array or value (depending upon the state type)
     *
     * ```
     * const clickCounterValue = model.responses.getState('click-counter', client.user);
     * ```
     *
     * @method getState
     * @param {String} name
     * @param {String} identityId
     * @returns {String | Number | Boolean | String[] | Number[] | Boolean[]}
     */

  }, {
    key: 'getState',
    value: function getState(name, identityId) {
      if (!identityId) {
        _utils.logger.warn('Identity not found for Model.getState(' + name + ')');

        return null;
      }

      if (this._trackers[name]) {
        return this._trackers[name].getValue(identityId);
      } else {
        throw new Error(_layerError.ErrorDictionary.modelStateNotRegistered);
      }
    }
    /**
     * Returns an array of results where:
     *
     * 1. Each result is of the form `{identityId: 'layer:///identities/frodo-the-dodo', value: 'red'}`
     * 2. Only returns state from the specified Identities
     * 3. Only returns state where a response for that state was sent for that identity
     * 4. A nulled value _will_ still be returned, only if the value was never set will it be left out.
     *
     * ```
     * const allClickCounterValues = model.responses.getStates('click-counter', [client.user, otherIdentity]);
     * allClickCounterValues.forEach(result => console.log(`${result.identityId} has a counter of ${result.value}`));
     * ```
     *
     * @method getStates
     * @param {String} name
     * @param {String[]} identityIds
     * @returns {Object[]}
     * @returns {String} return.identityId
     * @returns {String | Number | Boolean | String[] | Number[] | Boolean[]} return.value
     */

  }, {
    key: 'getStates',
    value: function getStates(name, identityIds) {
      identityIds = identityIds.filter(function (identity) {
        if (identity) return true;

        _utils.logger.warn('Identity not found for Model.getStates(' + name + ')');

        return false;
      });

      if (this._trackers[name]) {
        return this._trackers[name].getValues(identityIds);
      } else {
        throw new Error(_layerError.ErrorDictionary.modelStateNotRegistered);
      }
    }
    /**
     * Whenever the Response Summary is updated, import its data into all Trackers.
     *
     * 1. Iterate over every Tracker
     * 2. Call `importData` on each tracker, getting a list of zero or more state changes across all Identities for that tracker's state
     * 3. Trigger change events notifying listeners of any state changes
     *
     * @method parseResponsePart
     * @param {Layer.Core.MessagePart} part
     */

  }, {
    key: 'parseResponsePart',
    value: function parseResponsePart(part) {
      var _this4 = this;

      var hasChanges = false;
      this.part = part;
      var payload = JSON.parse(part.body);
      Object.keys(this._trackers).forEach(function (stateName) {
        var tracker = _this4._trackers[stateName]; // Note that `synchronize` has no knowledge of what states for what identities have
        // changed, and will import `stateName` for each Identity and identify changes.
        // Typically, multiple Identities would not change in a single update.
        // synchronize returns an array of zero or more Layer.Core.CRDT.Changes objects

        var changes = tracker.synchronize(payload); // Typically changes would be [] for most states,

        changes.forEach(function (change) {
          hasChanges = true;

          _this4.parentModel._triggerAsync('message-type-model:change', {
            property: 'responses.' + stateName,
            newValue: change.value,
            oldValue: change.oldValue,
            identityId: change.identityId
          });
        });
      });
      return hasChanges;
    }
    /**
     * Get the Response Message value corresponding to the given `responseName` and `identityId`.
     *
     * @method getResponse
     * @param {String} responseName    Name of the response to lookup
     * @param {String} identityId         Identity ID of the user who made the response
     * @removed
     */

    /**
     * Get _All_ responses from all users that contain the specified `responseName`
     *
     * ```
     * var responses = model.responses.getStates("selection", null);
     * responses.forEach(response => {
     *   const identity = client.getIdentity(response.identityId);
     *   console.log(`${identity.displayName} selected ${response.value}`);
     * }
     * ```
     *
     * This method returns an array of all responses from all users who have a `responseName`, where each element
     * in the array contains:
     *
     * * `identityId` of the user who sent that response
     * * `value` the value of the response
     *
     * Note that a user who has set a `responseName` and then later cleared it will still have a `responseName`
     * property whose value may be an empty string, null, or other empty values. These results are included in the
     * array.
     *
     * @method getResponses
     * @param {String} responseName
     * @param {String[]} [identityIds=null] Only include results from these authorized users (optional)
     * @returns {Object[]} responses
     * @removed
     */

  }]);

  return MessageTypeResponseSummary;
}(_root2.default);

MessageTypeResponseSummary.prototype.parentModel = null;
MessageTypeResponseSummary.prototype._trackers = null;
MessageTypeResponseSummary.prototype._currentResponseModel = null;
MessageTypeResponseSummary.prototype._sendResponseTimeout = null;
/**
 * The {@link Layer.Core.MessagePart} object that this model represents.
 *
 * @property {Layer.Core.MessagePart} part
 */

MessageTypeResponseSummary.prototype.part = null;
MessageTypeResponseSummary._supportedEvents = ['change'].concat(_root2.default._supportedEvents);
MessageTypeResponseSummary.inObjectIgnore = _root2.default.inObjectIgnore;

_root2.default.initClass.apply(MessageTypeResponseSummary, [MessageTypeResponseSummary, 'MessageTypeResponseSummary', _namespace2.default]);

_syncable2.default.subclasses.push(MessageTypeResponseSummary);

module.exports = MessageTypeResponseSummary;
(0, _index.register)('application/vnd.layer.responsesummary-v2+json', MessageTypeResponseSummary);
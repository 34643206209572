'use strict';

var _constants = require('./constants');

var _constants2 = _interopRequireDefault(_constants);

var _core = require('./core');

var _core2 = _interopRequireDefault(_core);

var _utils = require('./utils');

var _utils2 = _interopRequireDefault(_utils);

var _version = require('./version');

var _version2 = _interopRequireDefault(_version);

var _settings = require('./settings');

var _settings2 = _interopRequireDefault(_settings);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

_settings2.default.client = new _core2.default.Client({});
/* eslint-disable import/first */

var onInitItems = [];

function init(options) {
  var client = _settings2.default.client;
  if (!client || client.isDestroyed) client = _settings2.default.client = new _core2.default.Client({});
  Object.keys(options).forEach(function (name) {
    _settings2.default[name] = options[name];
    if (client[name] !== undefined) client[name] = options[name];
  });
  onInitItems.forEach(function (itemDef) {
    return itemDef.item.apply(itemDef.context);
  });
  return client;
}

function onInit(item, context) {
  var found = false;
  onInitItems.forEach(function (itemDef) {
    if (itemDef.item === item) found = true;
  });
  if (!found) onInitItems.push({
    item: item,
    context: context
  });
}

module.exports = {
  Core: _core2.default,
  Utils: _utils2.default,
  Constants: _constants2.default,
  init: init,
  onInit: onInit,
  version: _version2.default,

  get client() {
    return _settings2.default.client;
  },

  Settings: _settings2.default
};
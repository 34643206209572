import * as Sentry from '@sentry/browser';
import config from '../config.json';

const reportSentry = (error, errorInfo) => {
  Sentry.withScope((scope) => {
    scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
};

const reportError = (error, errorInfo) => {
  if (config.IsSentryEnabled) {
    reportSentry(error, errorInfo);
  }
};

export default reportError;

/**
 * Simple class for managing value and operationId.
 *
 * @class Layer.Core.CRDT.AddOperation
 */
'use strict';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _utils = require('../../utils');

var _constants = require('../../constants');

var _namespace = require('../namespace');

var _namespace2 = _interopRequireDefault(_namespace);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

var AddOperation = function () {
  /**
   * Represents an "Add" operation
   *
   * @method constructor
   * @private
   * @param {Object} options
   * @param {Mixed} options.value   The value that is selected/set
   * @param {String[]} [options.ids]  Operation IDs that resulted in those values
   */
  function AddOperation(_ref) {
    var value = _ref.value,
        ids = _ref.ids;

    _classCallCheck(this, AddOperation);

    this.value = value;
    this.ids = ids && ids.length ? ids : [(0, _utils.randomString)(CRDTStateTracker.OperationLength)]; // eslint-disable-line no-use-before-define
  }

  _createClass(AddOperation, [{
    key: 'hasAnId',
    value: function hasAnId(ids) {
      for (var i = 0; i < ids.length; i++) {
        for (var j = 0; j < this.ids.length; j++) {
          if (this.ids[j] === ids[i]) return true;
        }
      }

      return false;
    }
  }, {
    key: 'removeId',
    value: function removeId(id) {
      var index = this.ids.indexOf(id);
      if (index !== -1) this.ids.splice(index, 1);
    }
  }]);

  return AddOperation;
}();
/**
 * A simple class for reporting on changes that need to be performed (or that have been performed via `synchronize()`)
 *
 * @class Layer.Core.CRDT.Changes
 */


var Changes = function () {
  /**
   * Represents a change
   *
   * @method constructor
   * @private
   * @param {Object} options
   * @param {String} options.type   The Operation type chosen from Layer.Constants.CRDT_TYPES
   * @param {String} options.name   The state name that this tracks
   * @param {String} options.id     The operation ID
   * @param {Mixed} options.value   The value that was added/removed
   * @param {Mixed} options.oldValue   The value that was replaced
   * @param {String} options.identityId The identityId of the user whose state this is
   */
  function Changes(_ref2) {
    var type = _ref2.type,
        operation = _ref2.operation,
        name = _ref2.name,
        id = _ref2.id,
        value = _ref2.value,
        oldValue = _ref2.oldValue,
        identityId = _ref2.identityId;

    _classCallCheck(this, Changes);

    this.type = type;
    this.operation = operation;
    this.name = name;
    this.id = id;
    this.value = value;
    this.oldValue = oldValue;
    this.identityId = identityId;
  }

  _createClass(Changes, [{
    key: 'toSerializableObject',
    value: function toSerializableObject() {
      return {
        operation: this.operation,
        type: this.type,
        value: this.value,
        name: this.name,
        id: this.id
      };
    }
  }]);

  return Changes;
}();
/**
 * Class for tracking, syncing and performing changes for a given user and one state variable of that user.
 *
 * @class Layer.Core.CRDT.StateTracker
 */


var CRDTStateTracker = function () {
  /**
   * @method constructor
   * @param {Object} options
   * @param {String} options.type   The Operation type chosen from Layer.Constants.CRDT_TYPES
   * @param {String} options.name   The state name that this tracks
   * @param {String} options.identityId  The Identity ID of the user this state is for
   */
  function CRDTStateTracker(_ref3) {
    var type = _ref3.type,
        name = _ref3.name,
        identityId = _ref3.identityId;

    _classCallCheck(this, CRDTStateTracker);

    this.type = type;
    this.name = name;
    this.identityId = identityId;
    this.adds = [];
    this.removes = new Set();
  }
  /**
   * Returns the current value of this state; either an array if its a Set or a value if not.
   *
   * @method getValue
   * @returns {Mixed}
   */


  _createClass(CRDTStateTracker, [{
    key: 'getValue',
    value: function getValue() {
      if (this.type === _constants.CRDT_TYPES.SET) {
        return this.adds.map(function (addOperation) {
          return addOperation.value;
        });
      } else if (this.adds.length) {
        return this.adds[0].value;
      } else {
        return null;
      }
    }
    /**
     * Add a value to the tracker and return Change Operations.
     *
     * @method add
     * @param {String|Number|Boolean} value
     * @returns {Layer.Core.CRDT.Changes[]}
     */

  }, {
    key: 'add',
    value: function add(value) {
      if (this.adds.filter(function (anAdd) {
        return anAdd.value === value;
      }).length) return [];
      var addOperation = new AddOperation({
        value: value
      });
      return this._add(addOperation) || [];
    }
    /**
     * Removes a value from this tracker and return Change Operations.
     *
     * @method remove
     * @param {String|Number|Boolean} value
     * @returns {Layer.Core.CRDT.Changes[]}
     */

  }, {
    key: 'remove',
    value: function remove(value) {
      var _this = this;

      var addOperations = this.adds.filter(function (anAdd) {
        return anAdd.value === value;
      });
      var changes = [];
      addOperations.forEach(function (addOperation) {
        addOperation.ids.forEach(function (id) {
          var localChanges = _this._remove(id);

          if (localChanges && localChanges.length) changes.push.apply(changes, _toConsumableArray(localChanges));
        });
      });
      return changes;
    }
    /**
     * Adds an operation to this tracker's state and return Change Operations.
     *
     * @method _add
     * @private
     * @param {Layer.Core.CRDT.AddOperation} addOperation
     * @returns {Layer.Core.CRDT.Changes[]}
     */

  }, {
    key: '_add',
    value: function _add(addOperation) {
      var _this2 = this;

      var oldValue = this.getValue();
      var removes = []; // Technically, there should only be a single `id` but the addOperation object does have to support multiple ids.

      var generateAddOps = function generateAddOps() {
        return addOperation.ids.map(function (id) {
          return new Changes({
            operation: 'add',
            type: _this2.type,
            name: _this2.name,
            value: addOperation.value,
            identityId: _this2.identityId,
            oldValue: oldValue,
            id: id
          });
        });
      }; // If all IDs have already been "removed" then this operation is a no-op
      // Note that we overwrite addOperation.ids to remove any removed IDs before this addOperation
      // object can be pushed into the adds array.


      addOperation.ids = addOperation.ids.filter(function (id) {
        return !_this2.removes.has(id);
      });
      if (addOperation.ids.length === 0) return null; // Current state is empty so we just add and return

      if (this.adds.length === 0) {
        this.adds.push(addOperation); // Return the cahnges.

        return generateAddOps();
      } // if we got here, adds is already non empty. If the operation has previously been applied then do nothing


      if (this.adds.filter(function (anAdd) {
        return addOperation.hasAnId(anAdd.ids);
      }).length) {
        return null;
      } // if we got here, adds is already non empty. First Writer Wins, so we can't add a new value - new operation goes to removes


      if (this.type === _constants.CRDT_TYPES.FIRST_WRITER_WINS) {
        addOperation.ids.forEach(function (id) {
          return _this2.removes.add(id);
        });
        return null;
      } // reselection enabled, so we first remove old selection


      if (this.type === _constants.CRDT_TYPES.LAST_WRITER_WINS || this.type === _constants.CRDT_TYPES.LAST_WRITER_WINS_NULLABLE) {
        var oldOperation = this.adds.pop();
        oldOperation.ids.forEach(function (anId) {
          return _this2.removes.add(anId);
        });
      } // Add the LWW, LWWN or Set operation to the adds array


      this.adds.push(addOperation); // Return the changes.

      removes.push.apply(removes, _toConsumableArray(generateAddOps()));
      return removes;
    }
    /**
     * Removes an operation to this tracker's state and return Change Operations.
     *
     * @method _remove
     * @private
     * @param {String} operationId   One id to be found in the AddOperation.ids value.
     * @returns {Layer.Core.CRDT.Changes[]}
     */

  }, {
    key: '_remove',
    value: function _remove(operationId) {
      var _this3 = this; // Remove operations are not supported on these behavior types


      if (this.type === _constants.CRDT_TYPES.FIRST_WRITER_WINS || this.type === _constants.CRDT_TYPES.LAST_WRITER_WINS) {
        return null;
      } // Remove this id from every AddOperation object in the adds array


      this.adds.forEach(function (addOperation) {
        return addOperation.removeId(operationId);
      }); // Remove any AddOperation objects that have no more operation ids

      var removedOperations = [];
      this.adds = this.adds.filter(function (addOperation) {
        if (!addOperation.ids.length) removedOperations.push(addOperation);
        return addOperation.ids.length;
      }); // Add this to the removed operations

      this.removes.add(operationId); // Return the changes

      if (removedOperations.length) {
        return removedOperations.map(function (addOperation) {
          return new Changes({
            operation: 'remove',
            type: _this3.type,
            name: _this3.name,
            identityId: _this3.identityId,
            id: operationId,
            value: addOperation.value,
            oldValue: null
          });
        });
      }
    }
    /**
     * Given a full Response Summary payload from the server, update this tracker's state and generate any needed change operations.
     *
     * @method synchronize
     * @param {Object} payload
     * @returns {Layer.Core.CRDT.Changes[]}
     */

  }, {
    key: 'synchronize',
    value: function synchronize(payload) {
      var _this4 = this;

      var initialValue = this.getValue();
      var userPayload = payload[this.identityId] || {};
      var _userPayload$name = userPayload[this.name],
          adds = _userPayload$name.adds,
          removes = _userPayload$name.removes;
      var oldAdds = this.adds;
      var oldRemoves = this.removes;
      this.adds = adds.map(function (add) {
        return new AddOperation(add);
      });
      this.removes = new Set(); // IE 11 does not support new Set(removes)

      removes.forEach(function (value) {
        return _this4.removes.add(value);
      });
      oldRemoves.forEach(function (operationId) {
        _this4._remove(operationId);
      });
      var addOperations = oldAdds.map(function (addOperation) {
        if (addOperation instanceof AddOperation) {
          return addOperation;
        } else {
          return new AddOperation({
            ids: addOperation.ids,
            value: addOperation.value
          });
        }
      });
      addOperations.forEach(function (addOperation) {
        _this4._add(addOperation);
      });
      var finalValue = this.getValue();
      return [new Changes({
        operation: finalValue ? 'add' : 'remove',
        type: this.type,
        name: this.name,
        value: finalValue,
        oldValue: initialValue,
        identityId: this.identityId
      })];
    }
  }]);

  return CRDTStateTracker;
}();

CRDTStateTracker.OperationLength = 6;
if (!_namespace2.default.CRDT) _namespace2.default.CRDT = {};
_namespace2.default.CRDT.CRDTStateTracker = CRDTStateTracker;
_namespace2.default.CRDT.Changes = Changes;
_namespace2.default.CRDT.AddOperation = AddOperation;
module.exports = {
  CRDTStateTracker: CRDTStateTracker,
  Changes: Changes
};
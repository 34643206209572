/**
 * @class Layer
 */
'use strict';

var _indexCore = require('./index-core');

var _ui = require('./ui');

var _ui2 = _interopRequireDefault(_ui);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/* eslint-disable import/first */


(0, _indexCore.onInit)(_ui2.default.init, _ui2.default);
module.exports = {
  UI: _ui2.default,
  Core: _indexCore.Core,
  Utils: _indexCore.Utils,
  Constants: _indexCore.Constants,
  init: _indexCore.init,
  onInit: _indexCore.onInit,
  version: _indexCore.version,
  client: _indexCore.client,
  Settings: _indexCore.Settings
};
if (typeof global !== 'undefined') global.Layer = global.layer = module.exports;
"use strict";

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
/* eslint-disable */

/*
 * Copied directly from https://www.npmjs.com/package/content-type-parser so that all arrow functions could be properly run through babeljs.
 * Can resume using the proper npm dependency as soon as IE11 is no longer being supported.
 */


var tokenRegexp = /^[!#$%&'*+\-.^_`|~0-9A-Za-z]+$/;
var contentTypeRegexp = /^(.*?)\/(.*?)([\t ]*;.*)?$/;
var parameterValueRegexp = /^(.*?)=(.*)$/;
var quotedStringRegexp = /"(?:[\t \x21\x23-\x5B\x5D-\x7E\x80-\xFF]|(?:\\[\t \x21-\x7E\x80-\xFF]))*"/;
var qescRegExp = /\\([\t \x21-\x7E\x80-\xFF])/g;
var quoteRegExp = /([\\"])/g;

function qstring(val) {
  if (tokenRegexp.test(val)) {
    return val;
  }

  return "\"" + val.replace(quoteRegExp, "\\$1") + "\"";
}

var ContentType = function () {
  function ContentType(type, subtype, parameterList) {
    _classCallCheck(this, ContentType);

    this.type = type;
    this.subtype = subtype;
    this.parameterList = parameterList;
  }

  _createClass(ContentType, [{
    key: "get",
    value: function get(key) {
      key = String(key).toLowerCase();
      var param = this.parameterList.reverse().find(function (v) {
        return v.key === key;
      });
      return param ? param.value : undefined;
    }
  }, {
    key: "set",
    value: function set(key, value) {
      key = String(key).toLowerCase();
      value = String(value);
      this.parameterList = this.parameterList.filter(function (v) {
        return v.key !== key;
      });
      this.parameterList.push({
        separator: ";",
        key: key,
        value: value
      });
    }
  }, {
    key: "isXML",
    value: function isXML() {
      return this.subtype === "xml" && (this.type === "text" || this.type === "application") || this.subtype.endsWith("+xml");
    }
  }, {
    key: "isHTML",
    value: function isHTML() {
      return this.subtype === "html" && this.type === "text";
    }
  }, {
    key: "isText",
    value: function isText() {
      return this.type === "text";
    }
  }, {
    key: "toString",
    value: function toString() {
      return this.type + "/" + this.subtype + this.parameterList.map(function (v) {
        return v.separator + (v.key ? v.key + "=" + qstring(v.value) : v.value);
      }).join("");
    }
  }]);

  return ContentType;
}();

module.exports = function parseContentType(contentType) {
  if (!contentType) {
    return null;
  }

  var contentTypeMatch = contentTypeRegexp.exec(contentType);

  if (contentTypeMatch) {
    var type = contentTypeMatch[1];
    var subtype = contentTypeMatch[2];
    var parameters = contentTypeMatch[3];

    if (tokenRegexp.test(type) && tokenRegexp.test(subtype)) {
      var parameterPattern = /([\t ]*;[\t ]*)([^\t ;]*)/g;
      var parameterList = [];
      var match = void 0;

      while (match = parameterPattern.exec(parameters)) {
        var separator = match[1];
        var keyValue = parameterValueRegexp.exec(match[2]);
        var key = void 0;
        var value = void 0;

        if (keyValue && tokenRegexp.test(keyValue[1])) {
          key = keyValue[1];

          if (quotedStringRegexp.test(keyValue[2])) {
            value = keyValue[2].substr(1, keyValue[2].length - 2).replace(qescRegExp, "$1");
          } else {
            value = keyValue[2];
          }
        }

        if (key) {
          parameterList.push({
            separator: separator,
            key: key.toLowerCase(),
            value: value
          });
        } else {
          parameterList.push({
            separator: separator,
            value: match[2]
          });
        }
      }

      return new ContentType(type.toLowerCase(), subtype.toLowerCase(), parameterList);
    }

    return null;
  }

  return null;
};
import { Component } from 'react';
import reportError from '../../common/reportError';

class ErrorBoundary extends Component {
  componentDidCatch(error, errorInfo) {
    reportError(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;

'use strict';

require('webcomponents.js/webcomponents-lite');

var _layerUi = require('./layer-ui');

var _layerUi2 = _interopRequireDefault(_layerUi);

require('./components/component');

require('./components/layer-replaceable-content');

require('./components/layer-conversation-view');

require('./handlers/text/autolinker');

require('./handlers/text/emoji');

require('./handlers/text/newline');

var _dateSeparator = require('./ui-utils/date-separator');

var _dateSeparator2 = _interopRequireDefault(_dateSeparator);

require('./messages');

require('./message-actions/open-expanded-view-action');

require('./message-actions/open-url-action');

require('./message-actions/open-file-action');

require('./message-actions/open-map-action');

require('./mixins/clickable');

require('./mixins/file-drop-target');

require('./mixins/message-handler');

require('./mixins/has-query');

require('./mixins/list');

require('./mixins/list-item');

require('./mixins/list-selection');

require('./mixins/list-item-selection');

require('./mixins/focus-on-keydown');

require('./messages/message-view-mixin');

require('./mixins/query-end-indicator');

require('./mixins/size-property');

require('./mixins/throttler');

var _mixins = require('./mixins');

var _mixins2 = _interopRequireDefault(_mixins);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // Load standard card actions
// Load Required Components

/*
 * This file is used to create a browserified build with the following properties:
 *
 * * Initializes webcomponent-light polyfil
 * * Hooks up all methods/properties in the layerUI namespace
 * * Initializes and registers all widgets of this library
 *
 * Note that you may create your own build that includes:
 *
 * * webcomponent polyfil
 * * Hooks up all methods/properties in the layerUI namespace
 * * Pick and choose modules from the lib folder to include
 *
 * NOTE: JSDuck is picking up on LayerUI and defining it to be a class
 * which we don't want; do not let JSDuck parse this file.
 *
 */


_layerUi2.default.mixins = _mixins2.default; // Load standard cards

_layerUi2.default.UIUtils.dateSeparator = _dateSeparator2.default; // If we don't expose global.layerUI then custom templates can not load and call window.Layer.UI.registerTemplate()

module.exports = _layerUi2.default;
import { layerClient } from '../get-layer';

import { sessionStoreKey } from './persistSession';

export default function logout() {
  localStorage.removeItem(sessionStoreKey);
  return new Promise((resolve) => {
    layerClient.logout(() => {
      window.dispatchEvent(new CustomEvent('logged-out'));
      // it never fails
      resolve();
    });
  });
}

/**
 * A helper mixin to add a size property components; component must add a `supportedSizes` property.
 *
 * ```
 * Layer.UI.registerComponent('my-size-supporting-widget', {
 *   mixins: [Layer.UI.mixins.SizeProperty],
 *   properties: {
 *     supportedSizes: {
 *       value: ['massive', 'tiny', 'nano']
 *     }
 *   }
 * });
 * var el = document.createElement('my-size-supporting-widget');
 * el.size = 'massive';
 * ```
 *
 * The above code will setup a Component that supports 3 possible sizes, and creates an instance whose size is `massive`.
 * This will set the CSS class on that node to `layer-size-massive`.
 *
 * @class Layer.UI.mixins.SizeProperty
 */
'use strict';

var _component = require('../components/component');

var _utils = require('../../utils');

var _index = require('./index');

var _index2 = _interopRequireDefault(_index);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

_index2.default.SizeProperty = module.exports = {
  properties: {
    /**
     * The supported sizes property lists all valid size values for the {@link #size} property.
     *
     * @property {String[]} supportedSizes
     */
    supportedSizes: {
      order: 100 // Must be initialized before size can be set

    },

    /**
     * The size for this component; setting this will set the CSS Class to `layer-size-xxx` where xxx is the size you set.
     *
     * @property {String} size
     */
    size: {
      order: 101,
      mode: _component.registerComponent.MODES.BEFORE,
      set: function set(newValue, oldValue) {
        var _this = this;

        if (this.supportedSizes.indexOf(newValue) === -1) {
          this.properties.size = oldValue;

          _utils.logger.info(this.tagName + ' does not support a size value of ' + newValue);
        } else {
          this.supportedSizes.forEach(function (size) {
            return _this.toggleClass('layer-size-' + size, size === newValue);
          });
        }
      }
    }
  }
};
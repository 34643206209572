'use strict';

var _namespace = require('../namespace');

var _namespace2 = _interopRequireDefault(_namespace);

var _utils = require('../../utils');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/*
 * Adds backwards compatability for the plain/text and image messages from Layer UI/Atlas.
 *
 */


function isPlainText(message) {
  var isCard = message.parts.filter(function (part) {
    return part.mime_type.match(/role=root/);
  }).length;
  var textPlainPart = message.parts.filter(function (part) {
    return part.mime_type === 'text/plain';
  })[0];
  return !isCard && textPlainPart;
}

function isImage(message) {
  var isCard = message.parts.filter(function (part) {
    return part.mime_type.match(/role=root/);
  }).length;
  var source = message.parts.filter(function (part) {
    return ['image/png', 'image/gif', 'image/jpeg'].indexOf(part.mime_type) !== -1;
  })[0];
  return !isCard && source;
}

function convertToTextMessage(message) {
  var TextModel = _namespace2.default.Client.getMessageTypeModelClass('TextModel');

  if (TextModel) {
    var textPlainPart = message.parts.filter(function (part) {
      return part.mime_type === 'text/plain';
    })[0];
    textPlainPart.body = JSON.stringify({
      text: textPlainPart.body
    });
    textPlainPart.mime_type = TextModel.MIMEType + '; role=root';
  }
}

function convertToImage(message) {
  var ImageModel = _namespace2.default.Client.getMessageTypeModelClass('ImageModel');

  if (ImageModel) {
    var source = message.parts.filter(function (part) {
      return ['image/png', 'image/gif', 'image/jpeg'].indexOf(part.mime_type) !== -1;
    })[0];
    var preview = message.parts.filter(function (part) {
      return part.mime_type === 'image/jpeg+preview';
    })[0];
    var metaPart = message.parts.filter(function (part) {
      return part.mime_type.toLowerCase() === 'application/json+imagesize';
    })[0];
    var meta = metaPart ? JSON.parse(metaPart.body) : {};
    var uuid = (0, _utils.generateUUID)();
    var parts = [{
      id: message.id + '/parts/' + uuid,
      mime_type: ImageModel.MIMEType + ';role=root',
      body: JSON.stringify(meta)
    }, source];
    source.mime_type += ';role=source;parent-node-id=' + uuid;

    if (preview) {
      parts.push(preview);
      preview.mime_type += ';role=preview;parent-node-id=' + uuid;
    }

    message.parts = parts;
  }
}

module.exports = function upgardeMessages(message) {
  if (isPlainText(message)) {
    convertToTextMessage(message);
  } else if (isImage(message)) {
    convertToImage(message);
  }
};
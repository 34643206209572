'use strict';

require('./index-lite');

require('./receipt/layer-receipt-message-view');

require('./carousel/layer-carousel-message-view');

require('./buttons/layer-buttons-message-view');

require('./file/layer-file-message-view');

require('./location/layer-location-message-view');

require('./product/layer-product-message-view');

require('./feedback/layer-feedback-message-view');
/**
 * The Multi Identity tracker class tracks all state related to a given named state across all users.
 *
 * So if multiple users have all sent in values for "myStateName" one Multi Identity Tracker class will be instantiated
 * that will track all users's "myStateName" state.
 *
 * @class Layer.Core.CRDT.MultiIdentityStateTracker
 */
'use strict';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _settings = require('../../settings');

var _stateTracker = require('./state-tracker');

var _namespace = require('../namespace');

var _namespace2 = _interopRequireDefault(_namespace);

var _constants = require('../../constants');

var _layerError = require('../layer-error');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

var CRDTMultiIdentityStateTracker = function () {
  /**
   *
   * @method constructor
   * @param {Object} options
   * @param {String} name    Name of the state to be tracked
   * @param {String} options.type   The Operation type chosen from Layer.Constants.CRDT_TYPES
   */
  function CRDTMultiIdentityStateTracker(_ref) {
    var name = _ref.name,
        type = _ref.type;

    _classCallCheck(this, CRDTMultiIdentityStateTracker);

    this.users = {};
    this.name = name; // Too bad Object.values() isn't in IE11...

    var values = Object.keys(_constants.CRDT_TYPES).map(function (typeKey) {
      return _constants.CRDT_TYPES[typeKey];
    });

    if (values.indexOf(type) === -1) {
      throw new Error(_layerError.ErrorDictionary.invalidCRDTType);
    }

    this.type = type;
  }
  /**
   * If the ResponseSummary MessagePart is removed that contains this data, clear the data.
   *
   * @protected
   * @method reset
   */


  _createClass(CRDTMultiIdentityStateTracker, [{
    key: 'reset',
    value: function reset() {
      this.users = {};
    }
    /**
     * Adds tracking for the specified user (no-op if already tracked)
     *
     * @method _addUser
     * @private
     * @param {String} identityId
     */

  }, {
    key: '_addUser',
    value: function _addUser(identityId) {
      if (!this.users[identityId]) {
        this.users[identityId] = new _stateTracker.CRDTStateTracker({
          type: this.type,
          name: this.name,
          identityId: identityId
        });
      }
    }
    /**
     * Returns the value of this state for the specified Identity
     *
     * @method getValue
     * @param {String} identityId
     * @returns {String|Number|Boolean|String[]|Number[]|Boolean[]}
     */

  }, {
    key: 'getValue',
    value: function getValue(identityId) {
      if (this.users[identityId]) {
        return this.users[identityId].getValue();
      } else if (this.type === _constants.CRDT_TYPES.SET) {
        return [];
      } else {
        return null;
      }
    }
    /**
     * Returns the value for all of the specified identities if they have posted a Response Message for this state.
     *
     * A `null` input will return All Identities.
     *
     * @method getValues
     * @param {String[]} [identityIds=null]
     * @return {Object}
     * @return {String} return.identityId
     * @return {String|Number|Boolean|String[]|Number[]|Boolean[]} return.value
     */

  }, {
    key: 'getValues',
    value: function getValues(identityIds) {
      var _this = this;

      return identityIds.map(function (identityId) {
        var tracker = _this.users[identityId];

        if (tracker) {
          return {
            identityId: identityId,
            value: tracker.getValue()
          };
        }

        return null;
      }).filter(function (result) {
        return result;
      }); // filter out the null results that lack a tracker
    }
    /**
     * Adds a value for this state for the current authenticated user.
     *
     * @method addValue
     * @param {String|Number|Boolean} value
     * @returns {Layer.Core.CRDT.Changes[]}
     */

  }, {
    key: 'addValue',
    value: function addValue(value) {
      var identityId = _settings.client.user.id;

      this._addUser(identityId);

      return this.users[identityId].add(value);
    }
    /**
     * Removes a value for this state for the current authenticated user.
     *
     * @method removeValue
     * @param {String|Number|Boolean} value
     * @returns {Layer.Core.CRDT.Changes[]}
     */

  }, {
    key: 'removeValue',
    value: function removeValue(value) {
      var identityId = _settings.client.user.id;

      this._addUser(identityId);

      return this.users[identityId].remove(value);
    }
    /**
     * Given a full Response Summary payload from the server, update this tracker's state and generate any needed change operations.
     *
     * @method synchronize
     * @param {Object} payload
     * @returns {Layer.Core.CRDT.Changes[]}
     */

  }, {
    key: 'synchronize',
    value: function synchronize(payload) {
      var _this2 = this;

      var changes = [];
      Object.keys(payload).forEach(function (identityId) {
        var userFullState = payload[identityId];
        var userState = userFullState[_this2.name];

        if (userState) {
          _this2._addUser(identityId);

          var localChanges = _this2.users[identityId].synchronize(payload);

          changes.push.apply(changes, _toConsumableArray(localChanges));
        }
      });
      return changes;
    }
  }]);

  return CRDTMultiIdentityStateTracker;
}();

module.exports = CRDTMultiIdentityStateTracker;
if (!_namespace2.default.CRDT) _namespace2.default.CRDT = {};
_namespace2.default.CRDT.CRDTMultiIdentityStateTracker = CRDTMultiIdentityStateTracker;
'use strict';

module.exports = {
  WIDTH: {
    FULL: 'full-width',
    FLEX: 'flex-width',
    ANY: 'any-width'
  },
  FOCUS: {
    NEVER: 0,
    ALWAYS: 1,
    DESKTOP_ONLY: 2
  },
  CONVERSATIONS_SORT: {
    LAST_MESSAGE: 1,
    CREATED_AT: 2
  }
};